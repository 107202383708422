import React from "react";
import { useNavigate  } from 'react-router-dom'; 
import { useEffect } from "react";
import { Button, Grid, Typography, Card, CardContent, CardMedia, Divider } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { mainHeadingColor } from "../theme";

export default function ItemCard({item, index}) {

    const navigate = useNavigate();

    const handleClick = () =>{
        navigate(`../item/${item.item_id}`)
    }

    useEffect(() => {
        
    }, [item])

    return (
        <>

        <Grid item xs={12} sm={12} md={12} style={{borderRadius: '20'}}>   
            <Card>
                <CardContent>
                    <div style={{ cursor: 'pointer' }} onClick = {() => {handleClick()}}>
                        <Grid container style = {{ height: '330px'}}>
                            <Grid item xs={12} sm = {12} md = {12} style={{ height: '220px', overflow: 'hidden' }}>
                                <CardMedia
                                    component="img"
                                    image={`${process.env.REACT_APP_BACKEND_URL}/media${item.image}`}
                                    title={item.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm = {12} md = {12} style={{
                                    height: '45px', // Set your desired maximum height here
                                    overflow: 'hidden'  // This will hide any content that exceeds the maximum height
                                  }}>
                                <Typography variant ="itemCardHeader" align="left" title={item.name}>
                                    {item.name.length > 60 ? `${item.name.substring(0, 57)}...` : item.name}
                                </Typography >
                            </Grid>
                            <Grid item xs={12} sm ={12} md = {12} mt = {1}>
                                <Divider/>
                            </Grid>
                            <Grid container mt= {0.5}>
                                <Grid item xs={9}>
                                    ${item.pricePerDay} / day
                                </Grid>
                                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{
                                        width: '25px', // Reduced size
                                        height: '25px', // Reduced size
                                        borderRadius: '50%',
                                        backgroundColor: mainHeadingColor,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        lineHeight: '20px',
                                        color:'white'
                                    }}>
                                        <Typography align="center" variant="smallDescriptor" >
                                        {item.rating ? item.rating : '5.0'}
                                        </Typography>
                                    </div>
                                                                
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant ="smallDescriptor" align="left" title={item.addressName}>
                                        {item.addressName}
                                    </Typography >
                                </Grid>
                                <Grid item xs={2} sm = {2} md = {2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <Typography variant ="smallDescriptor" color="textSecondary" align="left" title={item.name}>
                                        {item.distance}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    </>)
}
