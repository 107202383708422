import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid, Box, CircularProgress, IconButton } from '@mui/material';
import { useStyles } from '../styles'; 
import ItemCard from './ItemCard';
import RentalCard from './RentalCard';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { circularSlice } from '../features/util/bannerHelpers';
import { backgroundColor } from '../theme';
import { determineItemsToShow } from './ItemBanner';
import StoreCard from './StoreCard';

export const StoreBanner = ({stores}) => {
    const classes = useStyles();

    const [storeShowing, setStoreShwowing] = useState([])
    const [storeIndex, setStoreIndex] = useState(0)
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(stores){
            setStoreShwowing(stores.slice(storeIndex, storeIndex + determineItemsToShow()))
            setIsLoading(false)
        }
      }, [stores]);

    

    const handleScroll = direction => {
      // Update itemIndex based on the scroll direction
      const newIndex =
        direction === 'left'
          ? (setStoreIndex - 1 + stores.length) % stores.length
          : (setStoreIndex + 1) % stores.length;
          setStoreIndex(newIndex);
    };
  
    return (
        <>
        {isLoading ?
            <Box sx={{display: 'flex', justifyContent: 'center' }}>
                <CircularProgress style={{ color: 'black' }}/>
            </Box> 
        :   
        <Paper 
          style={{backgroundColor: backgroundColor, borderRadius: '10', height: '100%', display: 'flex', flexDirection: 'row', position: 'relative', boxShadow: 'none'}}
        >
            <IconButton onClick={() => handleScroll('left')} disabled = {storeIndex === 0}>
              <ArrowLeftIcon />
            </IconButton>
            {storeShowing.map((store, index) => 
              <Grid container 
                key={store.id}
                style= {{maxWidth:'275px'}}
              >
                <StoreCard store = {store}/>
              </Grid>
            )}
            <IconButton onClick={() => handleScroll('right')} disabled = {storeIndex + determineItemsToShow() >= stores.length}>
              <ArrowRightIcon />
            </IconButton>
        </Paper>
    }
    </>
    );
  };