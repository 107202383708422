import React from 'react';
import { useUser } from '../../User/userContext';
import { Typography, Grid, Card, CardContent, Button, Divider, TextField } from "@mui/material";
import { useStyles } from '../../../styles';
import { useNavigate } from 'react-router-dom';
import { loginUser, checkAuth } from '../../User/actions';

export default function Login() {
    const { setUser } = useUser();
    const classes = useStyles();
    const navigate = useNavigate();

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    const handleUsername = (value) => {
        setUsername(value);
    };

    const handlePassword = (value) => {
        setPassword(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        loginUser({ 'username': username, 'password': password }).then(response => {
            checkAuth().then(response => {
                setUser({ ...response });
                navigate('/home/');
            });
        }).catch(error => {
            setErrorMessage("Incorrect Username or Password");
        });
    };

    const handleFedCmLogin = async () => {
        if ('credentials' in navigator) {
            try {
                const credential = await navigator.credentials.get({
                    federated: {
                        providers: ['https://accounts.google.com'],
                        clientId: '62292786249-5nfn5rp1dcj0c1lskjf5ek3feqha93c9.apps.googleusercontent.com'
                    },
                    mediation: 'required'
                });
                console.log(credential)
                if (credential) {
                    console.log(credential);
                    // Here, send `credential.id` or other relevant information to your backend for verification
                    // and further processing like creating a user session.
                }
            } catch (err) {
                console.error(err);
                // Handle errors (e.g., user closed the prompt)
            }
        } else {
            console.error('FedCM API is not available in this browser.');
        }
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} align="center">
                <Typography variant="h2">
                    Welcome back!
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4} mt={3}>
                <Card>
                    <CardContent>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography variant="title1">Sign in</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email"
                                        size="small"
                                        fullWidth
                                        onChange={(e) => handleUsername(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Password"
                                        type="password"
                                        size="small"
                                        fullWidth
                                        onChange={(e) => handlePassword(e.target.value)}
                                    />
                                </Grid>
                                {errorMessage && (
                                    <Grid item xs={12}>
                                        <span style={{ color: 'red' }}>{errorMessage}</span>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Button variant="contained" type="submit">Continue</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button onClick={handleFedCmLogin} variant="outlined">Sign in with Google</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <span onClick={() => { navigate('/createuser') }} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    Make a Yellow account
                </span>
            </Grid>
        </Grid>
    );
}
