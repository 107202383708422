import React, { useState } from 'react';
import { Card, CardMedia, Divider, Grid, IconButton, makeStyles, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useStyles } from '../styles';
import { mainHeadingColor } from '../theme';

const ImageViewerItem = ({ images, itemData }) => {
  const classes = useStyles();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  console.log(itemData)
  console.log(images)

  return (
    <Grid container xs ={12} className={classes.imageRoot}>
        <Grid item xs={1} >
            <IconButton onClick={handlePrev} color="primary">
                <ChevronLeft />
            </IconButton>
        </Grid>
        <Grid item xs={10} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Card>
                <Grid container item xs={12} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <Grid item xs={12}>
                        <div className={classes.imageCardContent}>
                            <CardMedia
                                className={classes.imageMedia}
                                component="img"
                                image={images[currentImageIndex]}
                                title={`Image ${currentImageIndex + 1}`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={10} md={10} mt={2}>
                        <Divider sx={{mt:0.5, mb:2}}/>
                    </Grid>
                    <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-start' }} mb={2}>
                        <Typography variant ="body1" align="center">
                            ${itemData.pricePerDay} / Day
                        </Typography>
                    </Grid>
                    <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end' }}  mb={2}>
                        <div style={{
                                        width: '25px', // Reduced size
                                        height: '25px', // Reduced size
                                        borderRadius: '50%',
                                        backgroundColor: mainHeadingColor,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        lineHeight: '20px',
                                        color:'white'
                                    }}>
                                        <Typography align="center" variant="smallDescriptor" >
                                        {itemData.rating ? itemData.rating : '5.0'}
                                        </Typography>
                                    </div>
                    </Grid>   
                </Grid>
            </Card>
        </Grid>
        <Grid item xs={1}>
            <IconButton onClick={handleNext} color="primary">
            <ChevronRight />
            </IconButton>
        </Grid>
        </Grid>
  );
};

export default ImageViewerItem;