import { Typography, Grid, Button, Box, Divider, IconButton, TextField, InputAdornment, Avatar, Menu, MenuItem } from "@mui/material";
import { useNavigate  } from 'react-router-dom'; 
import React from "react";
import { useState, useEffect } from "react";
import { useStyles } from '../styles'; 
import { mainHeadingColor } from "../theme";
import { useUser } from '../features/User/userContext';
import { getLocation } from "./Header";
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function Footer() {

    useEffect(() => {
    }, []);


    return (
        <footer>
                <Grid container mt={7} style = {{backgroundColor: mainHeadingColor, height: '100%', width:'100%' }}>
                    <Grid container item xs={12} sm = {12} md ={4} lg={4} mt={1}>
                        <Grid item xs={12}>
                            Support
                        </Grid>
                        <Grid item xs={12}>
                            Help Center
                        </Grid>
                        <Grid item xs={12}>
                            Disability
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm = {12} md ={4} lg={4}  mt={1}>
                        <Grid item xs={12}>
                            Property Owner
                        </Grid>
                        <Grid item xs={12}>
                            Rent your items
                        </Grid>
                        <Grid item xs={12}>
                            Insurance
                        </Grid>
                        <Grid item xs={12}>
                            Owner resources
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm = {12} md ={4} lg={4}  mt={1}>
                        <Grid item xs={12}>
                            Yelo
                        </Grid>
                        <Grid item xs={12}>
                            New Features
                        </Grid>
                        <Grid item xs={12}>
                            Feedback
                        </Grid>
                        <Grid item xs={12}>
                            Careers
                        </Grid>
                        <Grid item xs={12}>
                            Investors
                        </Grid>
                    </Grid>
                </Grid>
        </footer>
    )
}
