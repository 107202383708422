import { createTheme } from '@mui/material';

function pxToRem(value) {
    return `${value / 19}rem`;
  }
  
  function responsiveFontSizes({ sm, md, lg }) {
    return {
      '@media (min-width:600px)': {
        fontSize: pxToRem(sm)
      },
      '@media (min-width:900px)': {
        fontSize: pxToRem(md)
      },
      '@media (min-width:1200px)': {
        fontSize: pxToRem(lg)
      }
    };
  }
 
export const mainComponentColor = '#1d195e' 
export const contrastingColor = '#f7f5f5'
export const mainHeadingColor = '#1d195e'
export const backgroundColor = '#fcfcfc'
  
export const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
        // Add your custom breakpoints as needed
        custom: 1500, // Example of a custom breakpoint
      },
    },
    palette: {
      primary: {
        main: mainComponentColor,
        contrastText: contrastingColor
      },
    },
    typography:{
        fontFamily: '"Poppins", sans-serif',
        h1: {
            fontWeight: 700,
            lineHeight: 80 / 64,
            fontSize: pxToRem(20),
            ...responsiveFontSizes({ sm: 25, md: 30, lg: 35 }),
            color: mainComponentColor,
        },
        h2: {
          fontWeight: 700,
          lineHeight: 80 / 64,
          // fontSize: pxToRem(40),
          ...responsiveFontSizes({ sx: 30, sm: 50, md: 60, lg: 80 }),
          color: mainComponentColor,
        },
        body1:{
          fontWeight: 370,
          lineHeight: 1,
          fontSize: pxToRem(15),
          ...responsiveFontSizes({ sm: 15, md: 18, lg: 20 })
        },
        body2: {
          fontWeight: 400,
          lineHeight: 1.25,
          fontSize: pxToRem(16),
          ...responsiveFontSizes({ sm: 18, md: 20, lg: 22 })
        },
        title1: {
          fontWeight: 600,
          lineHeight: 1.2,
          fontSize: pxToRem(20),
          ...responsiveFontSizes({ sm: 28, md: 32, lg: 36 })
        },
        contrast:{
            fontWeight: 600,
            lineHeight: 1.2,
            fontSize: pxToRem(20),
            ...responsiveFontSizes({ sm: 28, md: 32, lg: 36 }),
            color: contrastingColor
        },
        fieldTitle : {
          fontWeight: 500,
          lineHeight: 1.4,
          fontSize: pxToRem(18),
          ...responsiveFontSizes({ sm: 20, md: 22, lg: 24 }),
          color: mainComponentColor
        },
        descriptor:{
          fontWeight: 1000,
          lineHeight: 0.4,
          fontSize: pxToRem(12),
          marginTop: 0.2,
          ...responsiveFontSizes({ sm: 10, md: 12, lg: 14 })
        },
        smallDescriptor:{
          fontWeight: 1000,
          lineHeight: 0.3,
          fontSize: pxToRem(8),
          marginTop: 0.2,
          ...responsiveFontSizes({ xs: 5, sm:5 , md: 8, lg: 12 })
        },
        itemCardHeader:{
          fontWeight: 600,
          lineHeight: 2,
          fontSize: pxToRem(12),
          marginTop: 0.2,
          ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 })
        },
        locationText : {
            fontWeight: 800,
            lineHeight: 0.77,
            fontSize: pxToRem(12),
            ...responsiveFontSizes({ sm: 10, md: 12, lg: 14 })
        },
        smallCleanText : {
          fontWeight: 400,
          lineHeight: 0.9,
          fontSize: pxToRem(10),
          ...responsiveFontSizes({ xs:10, sm: 10, md: 12, lg: 14 })
        }
    }
  });