import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Card, CardContent, Autocomplete, TextField } from '@mui/material';
import { useStyles } from '../styles';

export default function CancellationPolicy(props) {
    const navigate = useNavigate();
    const classes = useStyles();

    const timeOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'];
    const feeOptions = ['0', '25', '50', '75', '100'];

    useEffect(() => {
   
    }, [props.defaultFreeTime])

    const cancellationPolicy = `You may request to cancel your rental for a full refund, up to ${props.defaultFreeTime} days before the date of the rental pickup. 
    Cancellations within ${props.defaultFreeTime}-${props.partialTime} days before the rental pickup date will be charged ${props.partialFee}% of the total rental amount. 
    Cancellation requests made within ${props.partialTime} day of the rental pickup date will be charged ${props.fullFee}% of the total rental amount. 
    The original rental must be canceled if the new rental pickup date conflicts with the original rental period.
    When the owner of the rental approves the pickup date you are agreeing to these terms.`

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} mt={2}>
                {props.show ? <Typography>{cancellationPolicy}</Typography> :
                <Card>
                    <CardContent>
                        <Typography>
                            You may request to cancel your rental for a full refund, up to
                            <span>
                            <Autocomplete
                                options={timeOptions}
                                value={props.defaultFreeTime}
                                onChange={(event, newValue) => {
                                    props.setDefaultFreeTime(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard" // Use the standard variant to get only the underline
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: false, // Ensure the underline is shown
                                            style: { ...params.InputProps.style, textAlign: 'center' } // Ensure the input text is centered
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: { textAlign: 'center' } // Center-align the text within the input
                                        }}
                                        style={{ width: 60 }} // Adjust width as needed without a box
                                    />
                                )}
                                disableClearable
                                size="small"
                                style={{ display: 'inline-flex', verticalAlign: 'baseline' }}
                            />
                            </span>
                            days before the date of the rental pickup.<br/>
                            Cancellations within <span>
                            <Autocomplete
                                options={timeOptions.filter(option => option < props.defaultFreeTime)}
                                value={props.partialTime}
                                onChange={(event, newValue) => {
                                    props.setPartialTime(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard" // Use the standard variant to get only the underline
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: false, // Ensure the underline is shown
                                            style: { ...params.InputProps.style, textAlign: 'center' } // Ensure the input text is centered
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: { textAlign: 'center' } // Center-align the text within the input
                                        }}
                                        style={{ width: 60 }} // Adjust width as needed without a box
                                    />
                                )}
                                disableClearable
                                size="small"
                                style={{ display: 'inline-flex', verticalAlign: 'baseline' }}
                            />
                            </span>
                            to 
                            <span> {props.defaultFreeTime} </span>
                            days before the rental pickup date will be charged <span>
                            <Autocomplete
                                options={feeOptions}
                                value={props.partialFee}
                                onChange={(event, newValue) => {
                                    props.setPartialFee(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard" // Use the standard variant to get only the underline
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: false,
                                            style: { ...params.InputProps.style, textAlign: 'center' } // Ensure the input text is centered
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: { textAlign: 'center' } // Center-align the text within the input
                                        }}
                                        style={{ width: 50 }} // Adjust width as needed without a box
                                    />
                                )}
                                disableClearable
                                size="small"
                                style={{ display: 'inline-flex', verticalAlign: 'baseline' }}
                            />
                            </span>
                            % of the total rental amount. <br/>
                            Cancellation requests made within <span> {props.partialTime} </span> day of the rental pickup date will be charged <span>
                            <Autocomplete
                                options={feeOptions}
                                value={props.fullFee}
                                onChange={(event, newValue) => {
                                    props.setFullFee(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard" // Use the standard variant to get only the underline
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: false, // Ensure the underline is shown
                                            style: { ...params.InputProps.style, textAlign: 'center' } // Ensure the input text is centered
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: { textAlign: 'center' } // Center-align the text within the input
                                        }}
                                        style={{ width: 50 }} // Adjust width as needed without a box
                                    />
                                )}
                                disableClearable
                                size="small"
                                style={{ display: 'inline-flex', verticalAlign: 'baseline' }}
                            />
                            </span> % of the total rental amount. <br/>
                            The original rental must be canceled if the new rental pickup date conflicts with the original rental period.
                            When you confirm the rental you are agreeing to these terms.
                        </Typography>
                    </CardContent>
                </Card>
            }
            </Grid>
        </Grid>
    );
}
