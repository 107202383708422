import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom'; 
import { getItemsBySearch, getPopularSearch } from '../actions'
import { Typography, Avatar, Grid, SwipeableDrawer, Box, CircularProgress, Button, Paper, useMediaQuery, Dialog } from '@mui/material';
import { getMapOptions, getMapOptionsSearch, useStyles } from '../../../styles';
import ItemCard from '../../../components/ItemCard';
import ItemBanner from '../../../components/ItemBanner';
import ItemBannerVerticalEndless from '../../../components/ItemBannerVerticalEndless';
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import { styled } from '@mui/system';
import { useUser } from '../../User/userContext';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
// import { useMediaQuery } from '@material-ui/core';

const PullHandle = styled('div')(({ theme }) => ({
    width: '40px',
    height: '6px',
    backgroundColor: theme.palette.grey[400],
    borderRadius: '3px',
    position: 'absolute',
    top: '-12px', // Position it slightly outside the drawer
    left: '50%',
    transform: 'translateX(-50%)',
    cursor: 'grab', // Indicate that the handle can be dragged
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }));

const SearchResults = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const urllocation = useLocation()
    const { location } = useUser();
    // const {searchText} = useParams()

    const searchParams = new URLSearchParams(urllocation.search)
    const what = searchParams.get('what');
    const addressName = searchParams.get('addressName');
    let latitude = searchParams.get('latitude');
    let longitude = searchParams.get('longitude');
    const placeId = searchParams.get('place_id');
    const pickup = searchParams.get('pickup');
    const dropoff = searchParams.get('dropoff');

    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [openDialog, setOpenDialog] = useState(false)
    const [selectedOption, setSelectedOption] = useState()

    const [drawerOpen, setDrawerOpen] = React.useState(true);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const isXs = useMediaQuery(theme => theme.breakpoints.down('sm'))

    async function getLocationByIP() {
        try {
            const response = await fetch('https://ipapi.co/json/');
            const data = await response.json();
            return {
                latitude: data.latitude,
                longitude: data.longitude,
                city: data.city
            };
        } catch (error) {
            console.error('Error fetching location by IP:', error);
            return null;
        }
    }
    
    // Function to get location using Geolocation API
    function getLocationByGeolocation() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        });
                    },
                    (error) => {
                        console.error('Geolocation error:', error);
                        reject(error);
                    }
                );
            } else {
                reject(new Error('Geolocation not supported'));
            }
        });
    }

    useEffect(() => {
        const obj = {what, addressName, latitude, longitude, placeId, pickup, dropoff}
        console.log("HERE in seach")
        getItemsBySearch(obj).then(response =>{
            setItems(response)
            setIsLoading(false)
        })
    }, [what, addressName, pickup, dropoff]);

    console.log(searchParams)


    async function initMap() {
        try {
            const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");

            if (! (latitude && longitude)){
                if(location){
                    console.log(location.coords)
                    latitude = location.coords.latitude
                    longitude = location.coords.longitude
                    console.log("HERE 1")
                    console.log(latitude, longitude)
                }
                else{
                    try {
                        // Try to get location from Geolocation API
                        const geolocationData = await getLocationByGeolocation();
                        latitude = geolocationData.latitude;
                        longitude = geolocationData.longitude;
                        console.log("HERE")
                        console.log(latitude, longitude)
                    } catch {
                        // If Geolocation API fails, fallback to IP-based geolocation
                        console.log("HERE 2")
                        const ipLocationData = await getLocationByIP();
                        if (ipLocationData) {
                            latitude = ipLocationData.latitude;
                            longitude = ipLocationData.longitude;
                            console.log(`Guessed city: ${ipLocationData.city}`);
                        }
                    }
                }
            }
    
            const { mapOptions } = getMapOptionsSearch(latitude, longitude, items);
    
            const map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
    
            items.forEach((item, index) => {
                // Ensure latitude and longitude are valid numbers
                console.log(item)
                const lat = parseFloat(item.latitude);
                const lng = parseFloat(item.longitude);

                const customContent = document.createElement(`div`);
                customContent.className = classes.customMarker;
                customContent.innerHTML = `<strong>$${item.pricePerDay}</strong>`;
                customContent.style.cursor = 'pointer !important';

                const position = new window.google.maps.LatLng(lat, lng);

                try {
                    const marker = new window.google.maps.marker.AdvancedMarkerElement({
                        map: map,
                        position: position,
                        title: item.name,
                        content: customContent,
                        gmpClickable: true,
                    });

                    // const infoWindowContent = ReactDOMServer.renderToString(<ItemCard key={item.id} item={item} index={index} />);

                    const infoWindow = new window.google.maps.InfoWindow();
            
                    // Add a click event listener to customContent
                    marker.addListener('click', ({ domEvent, latLng }) => {
                        const { target } = domEvent;
                        // const infoWindowContent = document.createElement('div');
                        // ReactDOM.render(<ItemCard key={item.id} item={item} index={index} onNavigate={handleNavigate}/>, infoWindowContent);
                        // infoWindow.close();
                        // infoWindow.setContent(infoWindowContent);
                        // infoWindow.open(marker.map, marker);
                        setOpenDialog(true)
                        setSelectedOption({item, index})
                    });

                    // Log the marker to ensure it's created
                } catch (markerError) {
                    console.error('Error creating marker for item:', item, markerError);
                }
            });
        } catch (error) {
            console.error('Error initializing map:', error);
        }
    }

    useEffect(() => {
            if (window.google && window.google.maps && document.getElementById('map') && items.length > 0) {
                initMap()
            }
    }, [latitude, longitude, items, location, searchParams]);
    
    const handleNavigate = (path) => {
        navigate(path);
    };

    const handleClick = (id) => {
        navigate(`../item/${id}`)
    }
  
    return (
        <Grid container>
            {isLoading ?
                <Box sx={{display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress style={{ color: 'black' }}/>
                </Box> 
            :
                <>{items.length === 0 ?
                    <Grid container xs={12} md={12} mt={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="title1" align="center">
                                No items to display.. 
                            </Typography >
                        </Grid>
                        <Grid item xs={12} mt ={1}>
                            <Typography variant="body1" align="center">
                                Try rephrasing or looking for something else.. If you end up purchasing this item please come back and rent it to earn cash!
                            </Typography >
                        </Grid>
                    </Grid> 
                      :
                    <Grid container item xs={12} sm = {12} md = {12} ml ={{ md: 2 }} mt = {2}>
                         {/* Map on top for mobile (xs) and on the right for desktop (md) */}
                        <Grid item xs={12} order={{ xs: 1, md: 2 }} md={5.5} ml={{ md: 0.5 }} mb={{ xs: 2, md: 0 }}>
                            <Grid item id="map" style={{ height: '450px', width: '100%' }}></Grid>
                        </Grid>
                        <Grid item xs={12} order={{ xs: 2, md: 1 }} md={6} mb={{ xs: 2, md: 0 }}>
                            <ItemBannerVerticalEndless items={items} numberToShow={isXs ? 1 : 2}/>
                        </Grid>
                        <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
                            <Grid container 
                                style= {{maxWidth:'285px'}}
                            >
                            <ItemCard item={selectedOption ? selectedOption.item: null} index={selectedOption ? selectedOption.index : null} />
                            </Grid>
                        </Dialog>
                    </Grid>
                }
                </>
            }
        </Grid>
    );
  };
  
  export default SearchResults;