import React from "react";
import { useState, useEffect } from "react";
import { useUser } from '../../User/userContext';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { getItem, getImages } from "../actions";
import { Box, Button, Divider, Grid, Typography,CircularProgress, Card, CardContent, CardActions } from '@mui/material';
import Resizer from 'react-image-file-resizer';
import { getMapOptions, useStyles } from '../../../styles';
import { getImageFromUrl } from "../../util/imageHelper";
import ImageViewer from "../../../components/ImageViewer";
import Rent from "./Rent";
import { SetMealSharp } from "@mui/icons-material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { mainComponentColor, mainHeadingColor } from "../../../theme";
import { getRentalAddress } from "../../util/generalHelpers";
import ImageViewerItem from "../../../components/ImageViewerItem";

export default function Item() {

    const {id} = useParams()
    const [itemData, setItemData] = useState({})
    const [address, setAddress] = useState()
    const [images, setImages] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [viewImages, setViewImages]= useState([])
    const [openRent, setOpenRent] = useState(false)
    const classes = useStyles(); 

    const { user, isAuthenticated, logout } = useUser();
    const navigate = useNavigate();


    const displayImages = (imageResponse) => {
        const files = imageResponse.map(item => item.image);
        setViewImages(files)
    };

    const editIfOwner = () => {
        if(user && itemData.userProfile){
            if(user.user.id === itemData.userProfile.id){
                return (<Grid item xs={1} sm = {1} md = {1}>
                            <Button variant ="contained" align="right" onClick = {() => {navigate('/base/upload/'+id)}}>
                                Edit
                            </Button>
                        </Grid>)
            }
        }
    }

    useEffect(() => {
        getItem(id).then(response => {
            setAddress(getRentalAddress(response))
            setItemData(response)
            getImages(id).then(response => {
                setImages(response)
                displayImages(response)
                setIsLoading(false)
            })
        })

    }, [id])

    useEffect(() => {
        if (document.getElementById('map') && ((itemData.latitude && itemData.longitude) || (itemData.store.latitude && itemData.store.longitude))) { // Assuming itemData has latitude and longitude fields
            let lat = ''
            let long = ''
            if(itemData.useDefaultStoreLocation && itemData.store){
                lat = itemData.store.latitude
                long = itemData.store.longitude
            }
            else{
                lat = itemData.latitude
                long = itemData.longitude
            }
            const mapOptions=getMapOptions(lat, long)
            
            const map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
            // Add a marker for the location
            new window.google.maps.Marker({
                position: { lat: parseFloat(lat,10), lng: parseFloat(long,10)},
                map: map,
                title: itemData.addressName,
            });
        }
    }, [itemData, isLoading]);

    return (
        <>
        {isLoading ?
            <Box sx={{display: 'flex', justifyContent: 'center' }}>
                <CircularProgress/>
            </Box> 
            :
            <Grid container style={{display: 'flex', justifyContent:"center"}}>
                <Grid item xs={11} sm = {11} md = {11} mt={3}>
                    <Typography variant ="h2" align="center">
                        {itemData.name}
                    </Typography>
                </Grid>
                <Grid item xs={11} sm = {11} md = {11} style={{ textAlign: 'center', width: '100%' }}>
                    <LocationOnIcon style={{ color: mainComponentColor }}/><Typography variant = "h1" style={{ display: 'inline' }}>{address}</Typography>
                </Grid>
                <Grid item xs={10} md={6} mt={3}>
                     <ImageViewerItem images = {viewImages} itemData={itemData}/>
                </Grid> 
                <Grid container item xs={10} md={6} mt={3}>
                    <Grid item xs={10} md={12} mt={3}>
                        <Typography>
                            {itemData.description}
                        </Typography>
                    </Grid>

                    <Grid item xs={11} md = {12} mt={3}>
                        <Rent open={openRent} item = {itemData} images = {images.length !== 0 ? images[0]: null} setOpenRent = {setOpenRent}/>
                    </Grid> 
                </Grid> 
            </Grid>
        // :<Grid container justifyContent="center" alignItems="center">
        //     <Grid item xs={12} sm={7} md={10} mt = {2} justifyContent="center" alignItems="center">
        //                     <Grid item xs={11} sm = {11} md = {11}>
        //                         <Typography variant ="title1" align="left">
        //                             {itemData.name}
        //                         </Typography >
        //                     </Grid>
        //                     {editIfOwner()}
        //                     <Grid item xs={10} md={8} mt={3}>
        //                         <ImageViewer images = {viewImages}/>
        //                     </Grid> 

        //                     <Grid item xs={3} sm = {3} md = {3}>
        //                         <Typography variant ="descriptor">
        //                             Price Per Day: ${itemData.pricePerDay}
        //                         </Typography>
        //                     </Grid>
        //                     <Grid item xs = {3} sm = {3} md = {3}>
        //                         <Typography variant ="descriptor">
        //                             Quantity: {itemData.quantity}
        //                         </Typography>
        //                     </Grid>
        //                     <Grid item xs = {3} sm = {3} md = {3}>
        //                         <Typography variant ="descriptor">
        //                             Rating: 4.3 stars
        //                         </Typography>
        //                     </Grid>
                            // <Grid item xs={12} sm={12} md={12}>
                            //     <Divider sx={{mt:0.5, mb:2}}/>
                            // </Grid>
        //                     <Grid item xs = {12} sm = {12} md = {12}>
        //                         <Typography>
        //                             {itemData.description}
        //                         </Typography>
        //                     </Grid>

        //                     <Grid item xs={12} mt={2}>
        //                         <Typography>
        //                             Pick up location
        //                         </Typography>
        //                         <Typography>
        //                             {itemData.addressName}
        //                         </Typography>
        //                         <Grid item id="map" style={{ height: '200px', width: '60%' }}></Grid>
        //                     </Grid> 
        //                     <Grid item container justifyContent="center" xs={12} md = {12} mt={3}>
        //                         <Rent open={openRent} item = {itemData} images = {images.length !== 0 ? images[0]: null} setOpenRent = {setOpenRent}/>
        //                     </Grid> 
        //             </Grid>
        //     </Grid>
    }
    </>)
}
