import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Base from './components/Base'
import Base2 from './components/Base2'
import Home from './features/Item/Pages/Home'
import Item from './features/Item/Pages/Item'
import TestComponent from './features/Item/Pages/TestComponent';
import UploadItem from './features/Item/Pages/UploadItem'
import Category from './features/Item/Pages/Category'
import SearchResults from './features/Item/Pages/SearchResults'
import { useStyles } from './styles';
import Login from './features/User/Pages/Login'
import OwnerProfile from './features/User/Pages/OwnerProfile';
import RenterProfile from './features/User/Pages/RenterProfile';
import RentalInformation from './features/User/Pages/RentalInformation';
import CreateProfile from './features/User/Pages/CreateProfile';
import UserInformation from './features/User/Pages/UserInformation';
import StoreInformation from './features/User/Pages/StoreInformation';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payment from './features/Payment/Pages/Payment';
import BillingAccounts from './features/Payment/Pages/BillingAccounts';
import SuccessPage from './features/Payment/Pages/SuccessPage';
import RedoPage from './features/Payment/Pages/RedoPage';
import WelcomeBack from './features/Payment/Pages/WelcomeBack';


// ----------------------------------------------------------------------


export default function Router() {
  const classes = useStyles();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  return useRoutes([
    {
        path: `/`, element: <Base/>, children: [
          { path: '/', element: <Navigate to={`home`}/>},
          {
            path: 'home', element:<div className={classes.root}><Home/></div>
          },
          {
            path: 'item/:id', element: <div className={classes.root}><Item/></div>
          },
          {
            path: 'category/:id', element: <div className={classes.root}> <Category/> </div>
          },
          {
            path: 'search', element: <div className={classes.root}> <SearchResults/> </div>
          },
          {
            path: 'ownerprofile', element: <div className={classes.root}> <OwnerProfile/> </div>
          },
          {
            path: 'renterprofile', element: <div className={classes.root}> <RenterProfile/> </div>
          },
          {
            path: 'rentalinformation/:id?', element: <div className={classes.root}> <RentalInformation/> </div>
          },
          {
            path: `/createuser`, element: <div className={classes.root}><CreateProfile /> </div> 
          },
          {
            path: `/userinformation/:id?`, element: <div className={classes.root}><UserInformation/> </div> 
          },
          {
            path: `/storeinformation/:id?`, element: <div className={classes.root}><StoreInformation/> </div> 
          },
          {
            path: `/billingaccounts`, element: <div className={classes.root}><BillingAccounts /> </div> 
          },
          {
            path: `/successpage`, element: <div className={classes.root}><SuccessPage /> </div> 
          },
          {
            path: `/redopage`, element: <div className={classes.root}><RedoPage /> </div> 
          },
          {
            path: `/welcomebackpage`, element: <div className={classes.root}><WelcomeBack /> </div> 
          },
          {
            path: 'test', element: <TestComponent/>
          },
        ],
       
    },
    { 
      path: 'base', element:  <Base2/>, children: [
        { path: 'login', element: <div className={classes.root}> <Login/> </div>},
        {
          path: `payment/:id`, element: <div className={classes.root}><Elements stripe={stripePromise}> <Payment/> </Elements></div>
        },
        {
          path: 'upload/:id?', element: <div className={classes.root}> <UploadItem/> </div>
        }
      ]
    }
  ]);
}
