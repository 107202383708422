import React from "react";
import { useState, useEffect } from "react";
import { Grid, Box, CircularProgress } from '@mui/material';
import { useUser } from '../../User/userContext';
import Resizer from 'react-image-file-resizer';
import { useStyles } from '../../../styles';
import ImageViewer from "../../../components/ImageViewer";
import WelcomeBanner from "../../../components/WelcomeBanner";
import ItemBanner from "../../../components/ItemBanner";
import CategoryStrip from "../../../components/CategoryStrip"
import { getItemsHomePage } from "../actions";

export default function Home() {

    const [isLoading, setIsLoading] = useState(true)
    const [items, setItems] = useState()
    const classes = useStyles(); 

    const { user, location } = useUser();

    useEffect(() => {
        if(location){
            const origin = (location.coords.latitude).toString() + ',' + (location.coords.longitude).toString()
                getItemsHomePage(origin).then(response =>{
                    setItems(response)
                })
        }
        else{
            getItemsHomePage(false).then(response =>{
                setItems(response)
            })
        }
    }, [location])

    const banners = [
        'Do you really need to own that?',
        'Rent anything! Save money! Make money!',
        'Stay connected with us!',
    ];

    return (
        <>
        <Grid container>
                <Grid container mt={3} alignItems="center" justifyContent="center">
                    <Grid item xs = {10}>
                        <WelcomeBanner banners={banners}/>
                    </Grid>
                </Grid>
                <Grid container mt = {3} alignItems="center" justifyContent="center">
                    <Grid item xs = {11}>
                        <ItemBanner key={1} items={items} scroll circular/>
                    </Grid>
                </Grid>
                {/* <Grid container mt = {3} alignItems="center" justifyContent="center">
                    <Grid item xs = {11}>
                        <CategoryStrip/>
                    </Grid>
                </Grid> */}
        </Grid>
    </>)
}

