import React, { useEffect, useState } from 'react';
import { useUser } from '../userContext';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Box, CircularProgress, Paper, Button } from "@mui/material";
import { getItemsByOwner, getRentalsByOwner, getUserStore } from '../actions'
import ItemBanner from '../../../components/ItemBanner'
import WelcomeBanner from '../../../components/WelcomeBanner';
import { useStyles } from '../../../styles';
import { StoreBanner } from '../../../components/StoreBanner';

export default function OwnerProfile(){
  const { user, isAuthenticated, logout, location } = useUser();
  const classes = useStyles();
  const navigate = useNavigate()

  const [items, setItems] = useState()
  const [rentHistory, setRentHistory] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isRentalsLoading, setIsRentalsLoading] = useState(true)
  const [stores, setStores] = useState([])
  const [banners, setBanners] = useState(['Welcome'])

  console.log(stores)

  const today = new Date()
  const upcomingRentals = rentHistory.filter(i => new Date(i.pickupDate) > today).sort((a, b) => new Date(b.dropoffDate) - new Date(a.dropoffDate))
  const currentRentals = rentHistory.filter(i =>  today >= new Date(i.pickupDate) && today <= new Date(i.dropoffDate) && i.approved === true).sort((a, b) => new Date(b.dropoffDate) - new Date(a.dropoffDate))
  const pastRentals = rentHistory.filter(i => new Date(i.dropoffDate) < today).sort((a, b) => new Date(b.dropoffDate) - new Date(a.dropoffDate))

  console.log(rentHistory)

  if (!isAuthenticated) {
    navigate('/base/login/')
  }

  const refreshData = () => {
    setIsLoading(true)
    getRentalsByOwner(user.user.id).then(response => {
      setRentHistory(response)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if(user){
      getItemsByOwner(user.user.id).then(response => {
        setItems(response);
        setIsLoading(false)
      })
      getRentalsByOwner(user.user.id).then(response => {
        setRentHistory(response)
        setIsRentalsLoading(false)
      })
      getUserStore(user.user.id).then(response => {
        setStores(response)
      })
      setBanners([` Welcome  ${ user.user.nickname && user.user.nickname !== '' ? user.user.nickname : `${user.user.user.first_name}`}`])
    }
  }, [user])

  useEffect(() => {

  }, [rentHistory])

  return (
    <>{isLoading ?
              <Box sx={{display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress style={{ color: 'black' }}/>
              </Box> 
                :
      <Grid container>
        <Grid container item xs={12} sm = {12} md = {12} spacing={2} mt={1} justifyContent="center" alignItems="center">
            <Grid item xs = {10}>
                <WelcomeBanner banners={banners}/>
            </Grid>
            <Grid item xs = {10}>
            <Button onClick = {()=>{navigate('/billingaccounts/')}}>View Billing account</Button>
            </Grid>
            <Grid container item mt={1} xs={10} sm={10} md = {10}>
              <Grid item xs={6} sm={6} ms = {6}>
                <Paper className={classes.miniBanner} style={{borderRadius: '10'}}>
                  <Typography variant="contrast">My Items</Typography>
                </Paper>
              </Grid>
              <Grid item ml = {1} xs={1} sm={1} ms = {1}>
                <Button variant="contained" onClick = {()=>{navigate('/base/upload/')}}>
                  +
                </Button>
              </Grid>
            </Grid>
            <Grid item xs = {11}>
              <ItemBanner items={items}/>
            </Grid>

            <Grid container item mt={1} xs={10} sm={10} md = {10}>
              <Grid item xs={6} sm={6} ms = {6}>
                <Paper className={classes.miniBanner} style={{borderRadius: '10'}}>
                  <Typography variant="contrast">My Stores</Typography>
                </Paper>
              </Grid>
              <Grid item ml = {1} xs={1} sm={1} ms = {1}>
                <Button variant="contained" onClick = {()=>{navigate('/storeinformation/')}}>
                  +
                </Button>
              </Grid>
            </Grid>

            <Grid item xs = {11}>
              <StoreBanner stores={stores}/>
            </Grid>
                      
            <Grid container item mt={1} xs={10} sm={10} md = {10}>
              <Grid item xs={6} sm={6} ms = {6}>
                <Paper className={classes.miniBanner} style={{borderRadius: '10'}}>
                  <Typography variant="contrast">Current Rentals</Typography>
                </Paper>
              </Grid>
            </Grid>
            
            <Grid item xs = {11}>
              {currentRentals.length !== 0 ? <ItemBanner items={currentRentals} rentalInfo/>  :'You have no current rentals'}
            </Grid>
            <Grid container item mt={1} xs={10} sm={10} md = {10}>
              <Grid item xs={6} sm={6} ms = {6}>
                <Paper className={classes.miniBanner} style={{borderRadius: '10'}}>
                  <Typography variant="contrast">Upcoming Rentals</Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs = {11}>
              {upcomingRentals.length !== 0 ? <ItemBanner items={upcomingRentals} rentalInfo/> :'You have no upcoming rentals'}
            </Grid>
            

            <Grid container item mt={1} xs={10} sm={10} md = {10}>
              <Grid item xs={6} sm={6} ms = {6}>
                <Paper className={classes.miniBanner} style={{borderRadius: '10'}}>
                  <Typography variant="contrast">Past Rentals</Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs = {11}>
              {pastRentals.length !== 0 ?<ItemBanner items={pastRentals} rentalInfo/> :'You have no past rentals, go explore!'}
            </Grid>
      </Grid>
    </Grid>
    }
    </>
  );
};