import React, { useEffect, useState } from 'react';
import { useUser } from '../userContext';
import { Typography, Grid, Card, CardContent,Button, Divider, IconButton, TextField, InputAdornment, Avatar, Paper, CircularProgress } from "@mui/material";
import { useStyles } from '../../../styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Footer from '../../../components/Footer';
import { checkEmail, createStore, createUserProfile, editUserProfile, getStoreInformation, getUserInformation, updateStore } from '../actions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { mainHeadingColor } from '../../../theme';
import { ImageUpload } from '../../../components/ImageUpload';
import { getImageFromUrl } from '../../util/imageHelper';
import CancellationPolicy from '../../../components/CancellationPolicy';
import { initAutocomplete } from '../../util/locationHelpers';

export default function StoreInformation(){
    const classes = useStyles()
    const navigate = useNavigate()

    const location = useLocation();

    const {id} = useParams()

    const [profilePicture, setProfilePicture] = useState([]);

    const [storeData, setStoreData] = useState({})
    
    const [defaultFreeTime, setDefaultFreeTime] = useState('3');
    const [partialTime, setPartialTime] = useState('1');
    const [partialFee, setPartialFee] = useState('50');
    const [fullFee, setFullFee] = useState('100');

    const [disable, setDisable] = React.useState(true) 

    const { user } = useUser();

    const [isLoading, setIsLoading] = React.useState(true)
    const [loading, setLoading] = useState(false)

    console.log(document.getElementById('autocompleteInput'))

    const setStoreLocation = (name, latitude, longitude, placeIdTemp) => {
        const obj = {
            addressName: name,
            latitude: latitude,
            longitude: longitude,
            place_id: placeIdTemp
        };
        setStoreData(storeData => ({ ...storeData, ...obj }));
    }

    useEffect(() => {
        if(id){
            const fetchData = async () => {
                const response = await getStoreInformation(id);
                if(response.freeCancelationUntil){
                    setDefaultFreeTime(response.freeCancelationUntil )
                    setPartialTime(response.partialFeeCancelationUntil)
                    setPartialFee(response.partialFee)
                    setFullFee(response.cancelationFee)
                }
                setStoreData(response)
                const file = await getImageFromUrl(response.image);
                    
                if (file) { // Make sure the file is not false or undefined
                    setProfilePicture([{url: response.image, file}]);
                }
                    // setProfilePicture([{url: response.image, file:file}])
                setIsLoading(false)
            }
            fetchData();
        }
        else{
            setDisable(false)
            setIsLoading(false)
        }
    }, [])
    
    useEffect(() => {
   
    }, [storeData])

    useEffect(() => {
        const loadScript = () => {
            console.log("HERE LOAD SCRIPT")
            const script = document.createElement('script');
            script.id = 'google-maps-script';
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
            script.async = true;
            script.defer = true;
            script.onload = () => {
                if (window.google && window.google.maps && window.google.maps.places) {
                    initAutocomplete(setStoreLocation);
                    console.log('Google Maps script loaded and Autocomplete initialized');
                }
            };
            document.head.appendChild(script);
            setIsLoading(false)
        };

        // Check if Google Maps script is already loaded
        if (document.getElementById('google-maps-script')) {
            if (window.google && window.google.maps && window.google.maps.places) {
                console.log("HERE IN ALREADT SET")
                initAutocomplete(setStoreLocation);
                console.log('Google Maps script already loaded and Autocomplete initialized');
            } else {
                loadScript();
            }
        } else {
            loadScript();
        }
    }, [document.getElementById('autocompleteInput')]);

    const handleCancel = () => {
        getStoreInformation(id).then(response=>{
            setStoreData(response)
            if(response.freeCancelationUntil){
                console.log(response.freeCancelationUntil)
                setDefaultFreeTime(response.freeCancelationUntil )
                setPartialTime(response.partialFeeCancelationUntil)
                setPartialFee(response.partialFee)
                setFullFee(response.cancelationFee)
            }
            else{
                setDefaultFreeTime(3)
                setPartialTime(1)
                setPartialFee(50)
                setFullFee(100)
            }
            setProfilePicture([{url: response.image, file:getImageFromUrl(response.image).File}])
            setIsLoading(false)
        })
    }

    const handleSubmit = (e) => {
        console.log("HERE IN SUBMIT")
        e.preventDefault()
        console.log("PAST DEFAULT")
        //update
        const formData = new FormData();
        formData.append('name', storeData.name);
        formData.append('owner_id', user.user.id);
        if (profilePicture[0] && profilePicture[0].file) {
            formData.append('image', profilePicture[0].file);
        } // Assuming `profilePicture` is a File object
        formData.append('addressName', storeData.addressName);
        formData.append('latitude', storeData.latitude)
        formData.append('longitude', storeData.longitude)
        formData.append('place_id', storeData.place_id)
        formData.append('cancelationFee', fullFee)
        formData.append('freeCancelationUntil', defaultFreeTime)
        formData.append('partialFeeCancelationUntil', partialTime)
        formData.append('partialFee', partialFee)
        setLoading(true)
        if(id){
            updateStore(storeData.id, formData).then(response=>{
                setLoading(false)
                navigate('/ownerprofile/')
            })
        }
        else{
            createStore(formData).then(response =>{
                setLoading(false)
                navigate('/ownerprofile/')
            })
        }
    }

    const setData = (field, data) =>{
        const obj = {...storeData}
        obj[field] = data
        setStoreData(obj)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
   
    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={12} ms={12} lg={12} align="center">
                <Grid item cotnainer xs={12} mt={2}>
                    <Grid item xs={6}>
                        <Typography variant="h1">Store Information</Typography>
                    </Grid>
                </Grid>
            </Grid>
            { isLoading ?  <CircularProgress/> :
                <Grid container spacing = {1} item xs={12} sm = {6} md = {6} mt = {5}>
                    {id ? <Grid item xs={6} mb = {2}>
                            {<Button 
                                variant = "contained"
                                onClick={()=>{if(!disable){
                                    handleCancel()
                                }setDisable(!disable)}}
                            >   
                                {disable ? 'Edit Store' : 'Cancel'}
                            </Button>}
                    </Grid>
                    :
                    ''}
                    <form onSubmit={handleSubmit}>
                    <Grid container spacing = {2} item xs={12}>
                    <Grid item xs={12}>
                        <Typography>Store Name</Typography>
                    </Grid>
                    <Grid item xs={9} md ={6}>
                        <TextField size="small" required disabled = {disable} value = {storeData.name} fullWidth onChange={(e)=> setData('name',e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        Location
                    </Grid>
                    <Grid item xs={12}>
                    <TextField 
                        id="autocompleteInput" 
                        variant="outlined" 
                        fullWidth 
                        required
                        disabled = {disable}
                        value = { storeData.addressName }
                        onChange = {(event) => setData('addressName',event.target.value)}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            endAdornment: <div id="google-maps-script" />  // Placeholder for the script tag
                        }} 
                    />
                    </Grid>
                    <Grid item xs={12}>
                        Cancellation Policy
                    </Grid>
                    <Grid item xs = {12} sm = {12} md = {12}>
                        <CancellationPolicy
                            show = {disable}
                            defaultFreeTime = {defaultFreeTime} 
                            setDefaultFreeTime = {setDefaultFreeTime} 
                            partialTime = {partialTime}
                            setPartialTime ={setPartialTime}
                            partialFee = {partialFee}
                            setPartialFee = {setPartialFee}
                            fullFee = {fullFee}
                            setFullFee = {setFullFee}
                        />
                    </Grid>  

                    <Grid item xs={12}>
                        <Typography>Store Picture (optional)</Typography> 
                    </Grid>
                    <Grid item xs={9} md ={9}>
                        <ImageUpload images={profilePicture} disabled = {disable} setImages={setProfilePicture} limit={1}/>
                    </Grid>

                    {id ? <Grid item xs={12} sm = {12} md = {12} mt={2}>
                        {loading ? <CircularProgress/> : 
                            !disable ? <Button variant = "contained" type="submit">Edit Store</Button> : ''
                        }
                    </Grid>
                    :
                    <Grid item xs={12} sm = {12} md = {12} mt={2}>
                        {loading ? <CircularProgress/> : 
                            <Button variant = "contained" type="submit">Create Store</Button>
                        }
                    </Grid>
                    }
                    </Grid>
                    </form>
                </Grid>
            }
        </Grid>

    );
};