import React, { useState, useEffect } from "react";
import { Box, Autocomplete, Button, Grid, Typography, Divider, CircularProgress, CardMedia, Chip } from '@mui/material';
import { getRental, getRentalRating } from "../actions";
import { useParams } from "react-router-dom";
import { useStyles, getMapOptions } from '../../../styles';
import { useUser } from '../../User/userContext';
import { mainHeadingColor } from "../../../theme";
import WelcomeBanner from '../../../components/WelcomeBanner'
import HeaderCard from "../../../components/HeaderCard";
import Messages from "./Messages";
import ApproveRental from "../../../components/ApproveRental";
import { ReviewDisplay } from "../../../components/ReviewDisplay";
import { getRentalStatus } from "../../util/generalHelpers";
import ConfirmRental from "../../../components/ConfirmRental";
import { calculateTotalDays } from "../../Payment/paymentHelper";

export default function RentalInformation() {

    const classes = useStyles(); 
    const {id} = useParams()
    const { user } = useUser();

    const [isLoading, setIsLoading] = useState(true)
    const [rentalInformation, setRentalInformation] = useState({})
    const [openMessage, setOpenMessage] = useState(false)
    const [isOwner, setIsOwner] = useState(false)

    useEffect(() => {
        if (document.getElementById('map') && rentalInformation.latitude && rentalInformation.longitude) { // Assuming itemData has latitude and longitude fields
            const mapOptions = getMapOptions(rentalInformation.latitude, rentalInformation.longitude)
            const map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
            // Add a marker for the location
            new window.google.maps.Marker({
                position: { lat: parseFloat(rentalInformation.latitude,10), lng: parseFloat(rentalInformation.longitude,10)},
                map: map,
                title: rentalInformation.addressName,
            });
        }
    }, [rentalInformation, isLoading]);


    useEffect(() => {
        // setIsLoading(true)
        if(id){
            if(user){
                console.log(user.user)
                getRental(id).then(response =>{
                    setRentalInformation(response)
                    if(response.item.userProfile.user.id === user.user.user.id){
                        setIsOwner(true)
                    }
                    setIsLoading(false)
                
                })
            }
        }
    }, [user])

    const refreshData = () =>{
        setIsLoading(true)
        if(id){
            getRental(id).then(response =>{
                console.log(response)
                setRentalInformation(response)
                setIsLoading(false)
            })
        }
    }

    
    useEffect(() => {
        if (document.getElementById('map') && rentalInformation.latitude && rentalInformation.longitude) { // Assuming itemData has latitude and longitude fields
            const mapOptions = getMapOptions(rentalInformation.latitude, rentalInformation.longitude)
            const map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
            // Add a marker for the location
            new window.google.maps.Marker({
                position: { lat: parseFloat(rentalInformation.latitude,10), lng: parseFloat(rentalInformation.longitude,10)},
                map: map,
                title: rentalInformation.addressName,
            });
        }
    }, [rentalInformation, isLoading]);

    const pickupDate = new Date(rentalInformation.pickupDate)
    const dropoffDate = new Date(rentalInformation.dropoffDate) 
    var differenceInDays = calculateTotalDays(rentalInformation.pickupDate, rentalInformation.dropoffDate)
    
    const labelColor = getRentalStatus(rentalInformation)

    const getStatusDisplay = () => {
        if(labelColor.id === 4 && isOwner){
            return <ApproveRental rh={rentalInformation} refreshData={() => refreshData()}/>
        }
        else{
            return <Chip label={labelColor.label} sx={{ marginBottom: 1, height:'37px', borderRadius: '10px', '& .MuiChip-label': { fontSize: 10, color: "#fffcff", whiteSpace: 'normal'}, backgroundColor: labelColor.color }}/>
        }
    }

    const paymentDisplay = () => {
        const total = differenceInDays * rentalInformation.pricePerDay
        return (
            <Grid container xs = {12}> 
            <Grid item xs={12}>
                ${rentalInformation.pricePerDay} a day for {differenceInDays} days
            </Grid>
            <Grid item mt ={2}>
                <strong>total:</strong> ${total} 
            </Grid>
            </Grid>
        )
    }

    const dateDisplay = () => {
    return (
        <Grid container xs = {12}> 
            <Grid item xs = {6}> 
                <strong>pick up date:</strong> {pickupDate.toDateString()}
            </Grid>
            <Grid item xs = {6}> 
            <strong>drop off date:</strong> {dropoffDate.toDateString()} 
            </Grid>
            <Grid item xs = {6} mt = {2}> 
                <strong>total:</strong> {differenceInDays} days 
            </Grid>
            <Grid item xs = {6} mt = {2}>
                
                    {getStatusDisplay()}
                
                {/* { rentalInformation.approved === null && isOwner && new Date(rentalInformation.pickupDate) > new Date() ? 
                    <ApproveRental rh={rentalInformation} refreshData={() => refreshData()}/>
                    : <Typography>
                    <Chip label={labelColor.label} sx={{ marginBottom: 1, height:'37px', borderRadius: '10px', '& .MuiChip-label': { fontSize: 10, color: "#fffcff", whiteSpace: 'normal'}, backgroundColor: labelColor.color }}/>
                </Typography>
                } */}
            </Grid>
        </Grid>
    )
    }
    
    const ownerInfoDisplay = () => {
        const owner = rentalInformation.item.userProfile
        const store = rentalInformation.item.store
        const storeName = store ? store.name : ''
        const storeAddress = store ? store.addressName : ''
        const firstName = owner.user.first_name
        const lastName = owner.user.last_name
        return(
            <Grid container xs = {12}>
                <Grid item xs = {12}>
                    {store ? <> <strong>{storeName}</strong> {storeAddress} </>:  firstName + ' ' + lastName}
                </Grid>
            </Grid>
        )
    }

    const reviewDisplay = () => {
        return (
            <ReviewDisplay id={id} isOwner={isOwner} />
        )
    }

    const renterInfoDisplay = () => {
        const renter = rentalInformation.userProfile
        const firstName = renter.user.first_name
        const lastName = renter.user.last_name
        return(
            <Grid container xs = {12}>
                <Grid item xs = {12}>
                    {firstName + ' ' + lastName}
                </Grid>
            </Grid>
        )
    }

    return (
    <>
        {isLoading ?
              <Box sx={{display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress style={{ color: 'black' }}/>
              </Box> 
                :
                <>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={10} sm = {10} md = {10} mt={5}>
                        <Typography variant ="title1" align="left">
                            <WelcomeBanner banners = {[`Rental Receipt for: ${rentalInformation.itemName}`]}/>
                        </Typography >
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="center" item xs={12} sm = {12} md = {12} mt = {3}>
                    { openMessage ? <Messages rentId = {id} rentalInformation = {rentalInformation} />
                    :<Grid item xs = {10} sm = {10} md ={10} style={{ height: '100px' }}>
                        <Button style={{ width: '100%', height: '100%' }} onClick = {() => setOpenMessage(true)}>
                            Open Messages
                        </Button>
                    </Grid>
                    }
                    { openMessage ? <Grid item xs = {10} sm = {10} md ={10} style={{ height: '100px' }}>
                        <Button style={{ width: '100%', height: '100%' }} onClick = {() => setOpenMessage(false)}>
                            Close Messages
                        </Button>
                    </Grid> : null
                    }
                    <Grid item xs={12} mt={2} style={{ textAlign: 'center' }}>
                        <Typography>
                            {rentalInformation.addressName}
                        </Typography>
                    </Grid>
                    <Grid item id="map"  xs={5} sm = {5} md = {5} mt={2} style={{ borderRadius:10, height: '250px' }}></Grid>
                    <Grid container item xs={10} sm = {6} md = {6} mt={2} justifyContent="center" alignItems="center" height="250px" style={{ overflow: 'hidden' }}>
                        <CardMedia
                            component="img"
                            image={rentalInformation.itemImage ? rentalInformation.itemImage.resizedImage250by250: null}
                            title={rentalInformation.itemName}
                            style={{ width: 'auto', height: '100%', objectFit: 'contain' }}
                        />
                    </Grid>
                </Grid>
                <Grid container mt={1} spacing ={3}>
                    <Grid item xs={12} sm = {6} md = {12}>
                        <Grid container item xs={12} sm = {12} md = {12}>

                            <Grid item xs = {12} sm = {12} md ={6}>
                                <HeaderCard title = {'Dates'} return = {dateDisplay()}/>
                            </Grid>

                            <Grid item xs = {12} sm = {12} md ={6}>
                                <HeaderCard title = {'Payment'} return = {paymentDisplay()}/>
                            </Grid>

                            <Grid item xs =  {12} sm = {12} md ={6}>
                                <HeaderCard title = {'Ratings'} return = {reviewDisplay()}/>
                            </Grid>
                            <Grid item xs =  {12} sm = {12} md ={6}>
                                {isOwner ? <HeaderCard title = {'Renter Profile'} return ={renterInfoDisplay()}/> :<HeaderCard title = {'Owner Profile'} return ={ownerInfoDisplay()}/>}
                            </Grid>
                            <Grid item xs =  {12} sm = {12} md ={6}>
                                <HeaderCard title = {'Cancellation Policy'} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </>
        }
    </>
    )
}
