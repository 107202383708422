export const formatDateDB = (dt) => {
    return dt.toISOString()
}

export const formatDateNoTime = (date) => {
    const formattedDate = new Date(date).toISOString().split('T')[0];
    return formattedDate;
  };

  export const dateOptions = (date_string) =>{
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    return options
  }