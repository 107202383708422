import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid } from '@mui/material';
import { useStyles } from '../styles'; 
import { mainHeadingColor } from '../theme';

const WelcomeBanner = ({banners, color}) => {
    const classes = useStyles();
  
    const [currentBanner, setCurrentBanner] = useState(0);
  
    useEffect(() => {
      // Change the banner every 5 seconds
      const interval = setInterval(() => {
        setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
      }, 25000);
  
      return () => clearInterval(interval);
    }, [currentBanner, banners.length]);
  
    return (
      <Paper className={classes.banner} style={{borderRadius: '10', height: '100px', backgroundColor:color ? color : mainHeadingColor}}>
        <Typography variant="contrast">{banners[currentBanner]}</Typography>
      </Paper>
    );
  };
  
  export default WelcomeBanner;